ion-content ion-toolbar {
  --background: translucent;
}
.company-profile-page{
  .card-center{
    width: 90%;
    margin: 0 auto;
  }
  .profile-logo-wrap{
    img{
      width:260px;
      border-radius: 15px;
      margin: 0 auto;
    }
    .profile-logo{
      margin: 0 auto;
      position: relative;
      i{
        position: absolute;
        bottom: 12px;
        right: 10px !important;
        color: #000;
      }
    }
  }
}

@media (max-width: 575.98px) { 
  .company-profile-page{
    .card-center{
        width:90%;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) { 
  // .company-profile-page{
    
  // }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  // .company-profile-page{
  // }
}