.repprofile-page{
  .card-center{
    width: 90%;
    margin: 0 auto;
  }
  .border-right{
    border-right: 2px dashed #ccc;
  }
  .border-bottom{
    border-bottom: 2px dashed #ccc;
  }
  .profile-image-wrap{
    img{
      width:150px;
      height:150px;
      border-radius: 50%;
      margin: 0 auto;
    }
    .profile-image{
      margin: 0 auto;
      position: relative;
      i{
        position: absolute;
        bottom: 15px;
        left: 125px;
      }
    }
  }
  .profile-logo-wrap{
    img{
      width:260px;
      border-radius: 15px;
      margin: 0 auto;
    }
    .profile-logo{
      position: relative;
      margin: 0 auto;
      i{
        position: absolute;
        bottom: 10px;
        right: -25px;
      }
    }
  }
}

@media (max-width: 575.98px) { 
  .repprofile-page{
    .profile-logo {
      i {
        position: absolute !important;
        right: -18px !important;
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) { 
  .repprofile-page{
    .profile-logo {
      i {
        position: absolute !important;
        right: -18px !important;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .repprofile-page{
    .profile-logo {
      i {
        position: absolute !important;
        right: -22px !important;
      }
    }
  }
}