ion-content ion-toolbar {
  --background: translucent;
}
.dashboard-page{
  .card-center{
    width: 50%;
    margin: 0 auto;
  }
  .view-profiles-wrap{
    position: relative;
    .view-profiles-btn-single{
      background-color: var(--app-appbg);
      padding: 10px 15px;
      border-radius: 50%;
      margin-bottom: 10px;
      i{
        color: #fff;
      }
    } 
    .view-profiles-btn-group{
      background-color: var(--app-appbg);
      padding: 10px 11px;
      border-radius: 50%;
      margin-bottom: 10px;
      i{
        color: #fff;
      }
    }
  }
  
}

@media (max-width: 575.98px) { 
  .dashboard-page{
    .card-center{
        width:90%;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) { 
  .dashboard-page{
    .card-center{
        width:56%;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-page{
    .card-center{
        width:60%;
    }
  }
}