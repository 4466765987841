.login-page{
    .card-center{
        width:35%;
        display:block;
        margin: 0 auto;
    }
}

@media (max-width: 575.98px) { 
    .login-page{
        .card-center{
            width:90%;
        }
    }
 }

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) { 
    .login-page{
        .card-center{
            width:56%;
        }
    }
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
    .login-page{
        .card-center{
            width:60%;
        }
    }
 }

