.search-modal{
    ion-content{
      --ion-background-color:var(--app-popup-bg-color);
    }
    .searchbar {
      // min-height: 100vh;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      
      padding: 0px 15px 15px 15px;
      font-family: 'Roboto', sans-serif;
      .inner-form {
        width: 100%;
        .basic-search {
          margin-bottom: 5px;
          box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
          position: relative;
          .input-field{
            width: 100%;
            // position: relative;
            .icon-wrap {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 10;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-pack: end;
              justify-content: flex-end;
              -ms-flex-align: center;
              align-items: center;
              width: 60px;
              height: 100%;
              ion-icon {
                font-size: 32px;
                color: #999;
              }
            }
            input {
              padding: 10px 110px 10px 70px;
              height: 100%;
              border: 0;
              background: #fff;
              display: block;
              width: 100%;
              // padding: 10px 32px 10px 70px;
              font-size: 18px;
              color: #666;
              border-radius: 3px;
              height: 70px;
              color: #555;
          
            }
            .spinner-wrap {
              position: absolute;
              top: 0;
              right: 15px;
              z-index: 10;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-pack: end;
              justify-content: flex-end;
              -ms-flex-align: center;
              align-items: center;
              width: 60px;
              height: 100%;
              ion-icon {
                font-size: 32px;
                color: #999;
                cursor: pointer;
              }
            }
          }
          .suggestions-container {
            display: block;
            position: absolute;
            top: 72px;
            width: 100%;
            max-height: 220px;
            overflow-y: auto;
            border: 1px solid #f8f9fa;
            background-color: #fff;
            font-family: Helvetica, sans-serif;
            font-weight: 300;
            font-size: 16px;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            z-index: 2;
            box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
            ul.options{
              list-style: none;
              padding-left:0;
              margin:0;
              li{
                cursor: pointer;
                padding: 12px 15px;
                p{
                  padding: 0;
                  margin:0;
                  font-size: 13px;
                }
                &:not(:last-child) {
                  border-bottom: 1px solid #e7ebf0;
                }
                &:hover{
                  background-color: #e7ebf0;
                }
              }
              
            }
          }
        }
      }
    }
    
  }
  
  @media (max-width: 575.98px) { 
    .search-modal {
      .searchbar {
        .inner-form{ 
          .basic-search{ 
            .suggestions-container {
              max-height: 300px;
            }
          }
        }
      }
    }
  }
  
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 767.98px) { 
    .search-modal {
      .searchbar {
        .inner-form{ 
          .basic-search{ 
            .suggestions-container {
              max-height: 300px;
            }
          }
        }
      }
    }
  }

  // Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .search-modal {
    .searchbar {
      .inner-form{ 
        .basic-search{ 
          .suggestions-container {
            max-height: 220px;
          }
        }
      }
    }
  }
}