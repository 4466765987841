.press-release-page {
  .card-center {
    width: 90%;
    margin: 0 auto;
  }
  .border-right {
    border-right: 2px dashed #ccc;
  }
  .border-bottom {
    border-bottom: 2px dashed #ccc;
  }
  .quote {
    padding: 10px 20px;
    background-color: #ccc;
    color: #000;
    font-weight: bold;
    border-radius: 20px;
  }
}


.addpr-page {
  .card-center {
    width: 90%;
  }
  .border-right {
    border-right: 2px dashed #ccc;
  }
  .border-bottom {
    border-bottom: 2px dashed #ccc;
  }

  .profile-logo-wrap {
    img {
      width: 280px;
      border-radius: 15px;
      margin: 0 auto;
    }
    .profile-logo {
      position: relative;
      margin: 0 auto;
      i {
        position: absolute;
        bottom: 13px;
        right: 10px;
        color: #000;
      }
    }
  }
  
}
.preview-wrap{
  .ion-card{
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    background: blue;
    width: 500px;
  }  
}
.preview-card{
  width:98% !important;
  margin:0 auto;
}

