ion-content ion-toolbar {
  --background: translucent;
}
.profile-page{
  .profile-image-wrap{
    img{
      width:120px;
      /* height: 120px; */
      height: auto;
      border-radius: 50%;
      margin: 0 auto;
    }
    .profile-image{
      margin: 0 auto;
      position: relative;
    }
  }
  .profile-items-container{
    display: grid;
    grid-template-columns: repeat(auto-fill, 50%);
    justify-content: space-between;
    // grid-gap: 1rem;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
  .profile-page{
    .profile-image-wrap{
      img{
        width:120px;
        height: auto; // 120px
      }
    }
    .profile-items-container{
      grid-template-columns: repeat(auto-fill, 50%);
    }
  }
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  .profile-page{
    .profile-image-wrap{
      img{
        width:140px;
        height: auto; // 140px
      }
    }
    .profile-items-container{
      grid-template-columns: repeat(auto-fill, 50%);
    }
  }
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
  .profile-page{
    .profile-image-wrap{
      img{
        width:180px;
        height: auto; // 180px
      }
    }
    .profile-items-container{
      grid-template-columns: repeat(auto-fill, 33%);
    }
  }
 }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
  .profile-page{ 
    .profile-items-container{
      grid-template-columns: repeat(auto-fill, 33%);
    }
  }
 }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { 
  .profile-page{ 
    .profile-items-container{
      grid-template-columns: repeat(auto-fill, 33%);
    }
  }
}