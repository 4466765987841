.rfq-page{
    .card-center{
        width:96%;
        display:block;
        margin: 0 auto;
    }
    .popover-text{
        color: var(--app-text);
    }
}


@media (max-width: 575.98px) { 
    
 }

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) { 
    
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
    
 }

 


