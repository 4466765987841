.sponsor-page {
  .card-center {
    width: 90%;
    margin: 0 auto;
  }
  .border-right {
    border-right: 2px dashed #ccc;
  }
  .border-bottom {
    border-bottom: 2px dashed #ccc;
  }
  .profile-logo-wrap {
    img {
      width: 280px;
      border-radius: 15px;
      margin: 0 auto;
    }
    .profile-logo {
      position: relative;
      margin: 0 auto;
      i {
        position: absolute;
        bottom: 13px !important;
        right: 10px !important;
        color: #000;
      }
    }
  }
  ion-card-header + .card-content-ios {
    padding-inline-start:0%;
  }
  ion-thumbnail {
    --size: 74px !important;
    width: 84px;
    height: 84px;
    margin-bottom: 30px;
  }  
}

.preview-wrap{
  .ion-card{
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    background: blue;
    width: 500px;
  }  
}
.preview-card{
  width:98% !important;
  margin:0 auto;
} 
@media (max-width: 575.98px) { 
  .deals-page {
    .profile-logo {
      i {
        position: absolute !important;
        right: 0px !important;
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) { 
  .deals-page {
    .profile-logo {
      i {
        position: absolute !important;
        right: 0px !important;
      }
    }
  }
}