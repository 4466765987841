hr {
    border-bottom: 1px solid #ccc;
    margin: 10px 0;
    width: 100%;
}
// .hsUFxD .sc-bfmRBP {
//     margin-bottom: 50px !important;
// }
.card-title{
    font-size: 16px;
    font-weight: bold;
}
/*a.green{
    color: var(--app-green);
}*/
#buscat-form-wrap ion-select {
    width: 100% !important;
    font-size: 12px !important;
    justify-content: center;
}
.buscat-alert-wrapper {
    .alert-wrapper.sc-ion-alert-ios {
        min-width: 320px;
    }
}

/*
 * This adds a scroll bar to ionic alerts
 */
 .alert-radio-group::-webkit-scrollbar, .alert-checkbox-group::-webkit-scrollbar {
    width: 0.7em;
    display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track, .alert-checkbox-group::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb, .alert-checkbox-group::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

/* For Image Crop Modal Resize */
.view-modal-wrap{
    .modal-wrapper {
        border: 4px solid green;
        height: 80% !important;
        width: 80% !important;
    }
}

