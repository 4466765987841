.pdf-container{
    overflow: auto;
    .pdf-toolbar{
        padding: 10px 20px !important;
        background-color: #ccc;
        position: fixed;
        top: 56px;
        left:0;
        z-index: 100;
        width:100%;
        a{
            color: #000;
            padding: 0 10px;
            cursor: pointer;
        }
    }
}